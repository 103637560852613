import React from 'react'
import PropTypes from 'prop-types'
import { Form, Field } from 'react-final-form'
import { Col, Row } from 'react-flexbox-grid'
import { FULFILLED, PENDING, REJECTED } from 'config/status'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { styles as s, colors } from 'stylesheet'
import { withAuth } from 'context/AuthContext'

const StyledForm = styled.form({
  '.fields': css(s.bgGrey, {
    paddingTop: '1.5rem',
    paddingBottom: '1.75rem',
    marginBottom: '2rem',

    '.field': {
      marginBottom: '2rem',
    }
  }),

  '.reset-col': {
    textAlign: 'left',

    '.reset-button': css(s.white, {
      backgroundColor: 'transparent',
      border: 0,
      fontSize: '1rem',
      appearance: 'none',
      padding: 0,
      margin: 0,
      cursor: 'pointer',

      '&.disabled': {
        opacity: '0.5',
      },
    }),
  },

  '.label': {
    fontSize: '.85rem',
    padding: '5px',
    color: colors.white,
    backgroundColor: colors.red,
    display: 'block',
    marginBottom: '1rem',
    borderRadius: '5px',
  },

  '.sign-up, .reset-col .reset-button': {
    borderBottom: `2px solid ${colors.lightGrey}`,
  },
})

const LoginForm = (props) => {
  const {
    auth: {
      actions,
      error,
      status,
    },
  } = props

  const renderButtonText = () => {
    switch (status.auth) {
      case FULFILLED:
        return 'Done!';
      case PENDING:
        return 'Signing In...';
      case REJECTED:
        return 'Try Again';
      default:
        return 'Sign In';
    }
  }

  const onSubmit = ({ email, password }) => {
    actions.signInUser(email, password)
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={(formProps) => {
        const { handleSubmit, values } = formProps

        const handleReset = async () => {
          actions.resetPassword(values.email)
        }

        return (
          <StyledForm onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <div className='fields'>
                  <Row center='xs'>
                    <Col xs={10}>
                      {(error && error.code === 'auth/invalid-email') && (
                        <label className="label">Email Address is invalid</label>
                      )}
                      {(error && error.code === 'auth/user-not-found') && (
                        <label className="label">No account exists with Email Address</label>
                      )}
                      <Field
                        className="field"
                        label="email"
                        type="email"
                        name="email"
                        component="input"
                        placeholder="Email Address"
                      />
                    </Col>
                    <Col xs={10}>
                      {(error && error.code === 'auth/wrong-password') && (
                        <label className="label">Your password is incorrect</label>
                      )}
                      <Field
                        className='field'
                        label="password"
                        type="password"
                        name="password"
                        component="input"
                        placeholder="Password"
                      />
                    </Col>
                    <Col className='reset-col' xs={10}>
                      <span
                        className={`reset-button ${(
                          status.auth === PENDING
                          || status.reset === FULFILLED
                          || status.reset === PENDING
                          || !values.email
                        ) ? 'disabled' : ''}`}
                        onClick={handleReset}
                        disabled={(
                          status.auth === PENDING
                          || status.reset === FULFILLED
                          || status.reset === PENDING
                          || !values.email
                        )}
                      >
                        {status.reset === FULFILLED ? 'Password Reset Sent!' : 'Reset Password'}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {(error && error.code === 'auth/too-many-requests') && (
                  <label className="label text-left">Attempted to login too many times. Try again later.</label>
                )}
                <button
                  className='submit-button'
                  onClick={handleSubmit}
                  type='submit'
                  disabled={(
                    status.auth === PENDING
                    || status.auth === FULFILLED
                    || status.reset === PENDING
                  )}
                >
                  {renderButtonText()}
                </button>
              </Col>
            </Row>
          </StyledForm>
        )
      }}
    />
  );
};

LoginForm.propTypes = {
  auth: PropTypes.shape({
    actions: PropTypes.shape({
      resetPassword: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  error: PropTypes.shape({
    code: PropTypes.string,
  }),
};

export default withAuth(LoginForm)
