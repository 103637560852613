import React from 'react'
import { func, shape } from 'prop-types'
import { Link, NavLink, Switch, Route } from 'react-router-dom'
import { Col, Row } from 'react-flexbox-grid'
import { withAuth } from 'context/AuthContext'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { styles as s, colors } from 'stylesheet'
import LoginForm from './LoginForm'
import SignUpForm from './SignUpForm'

const StyledView = styled.div(
  {
    minHeight: '100vh',

    '.logo': {
      marginBottom: '2rem',
    },

    '.submit-button': {
      cursor: 'pointer',
    },

    '.lead-text': {
      fontSize: '1.25rem',
      color: colors.lightGrey,
    },

    '.text-gray': {
      color: colors.lightGrey,
    },

    '.text-uppercase': {
      textTransform: 'uppercase',
    },

    '.store-play-phone': {
      maxWidth: '240px',
      width: '100%',
      margin: '0 auto',
    },

    '.store-ios-phone': {
      maxWidth: '240px',
      width: '100%',
      margin: '0 auto',
    },

    '.store-image-col': {
      margin: '0 auto',
    },

    '.letter-spacing': {
      letterSpacing: '2px',
    },

    '.mb5': {
      marginBottom: '5rem',
    },

    '.mb3': {
      marginBottom: '3rem',
    },

    '.alert': {
      padding: '1rem',
      borderRadius: '5px',
      backgroundColor: colors.lightGrey,
      display: 'inline-block',
      marginTop: '2rem',
      marginBottom: '2rem',
    },

    '.nav-link': {
      fontSize: '1.15rem',
      fontWeight: 'bold',
      letterSpacing: '2px',
    },

    img: css(s.block, {
      maxWidth: '100%',
    }),

    nav: {
      marginBottom: '2rem',
      textAlign: 'left',

      a: css(s.lightGrey, {
        textTransform: 'uppercase',
        textDecoration: 'none',
        marginRight: '1rem',
        '&.active': css(s.white),
      }),
    },

    input: css(s.white, {
      width: '100%',
      backgroundColor: 'inherit',
      border: 0,
      borderBottom: '2px solid white',
      borderRadius: '0',
      webkitBorderRadius:'0px',
      fontSize: '1rem',
      lineHeight: '1.75rem',
    }),

    'button[type=submit]': css(s.bgRed, s.white, {
      width: '100%',
      border: 0,
      borderRadius: '0.25rem',
      fontSize: '1rem',
      padding: '1rem 0',
    }),

    '.badge': css({
      maxHeight: '40px',
      margin: '1rem auto 0',
    }),
  },
)

const StyledLink = styled.a(s.inlineBlock, {
  borderBottom: `2px solid ${colors.lightGrey}`,
  marginTop: '2rem',
  marginBottom: '2rem',
  ':hover': css({
    borderBottom: `2px solid ${colors.white}`,
  }),
})

const LoginView = (props) => {
  const { auth: { actions } } = props

  return (
    <StyledView as={Row} middle='xs' center='xs'>
      <Col xs={12} sm={10} >
        <Row left='xs'>
          <Col xs={12}>
            <div className='alert'><strong className='text-uppercase'>Thank You! </strong>You'll get an email shortly confirming your information.</div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <h1 className='text-uppercase letter-spacing'>Connect<br/>with the App</h1>
            <div className='lead-text mb5'>to stay up to date with event details and what's on the agenda.</div>
          </Col>
        </Row>

        <Row top="xs">
          <Col xs={12} lg={4} lgOffset={1}>
            <Row>
              <Col xs={12}>
                <nav className='nav-link'>
                  <NavLink to="/" exact>Sign Up</NavLink>
                  <NavLink to="/sign-in" exact>Sign In</NavLink>
                </nav>
              </Col>
              <Col xs={12}>
                <Switch>
                  <Route
                    path="/sign-in"
                    render={() => (
                      <LoginForm
                        {...props}
                        onSubmit={({ email, password }) => {
                          actions.signInUser(email, password)
                        }}
                      />
                    )}
                  />
                  <Route path="/" component={SignUpForm} />
                </Switch>
                <Row>
                  <Col xs={12} className='mb3'>
                    <Switch>
                      <Route
                        path='/'
                        exact
                        render={() => (
                          <StyledLink as={Link} to='/sign-in'>I already have an account</StyledLink>
                        )}
                      />
                      <Route
                        path='/sign-in'
                        render={() => (
                          <StyledLink as={Link} to='/'>Create an account</StyledLink>
                        )}
                      />
                    </Switch>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={6} lgOffset={1} className='store-image-col mb3'>
            <Row bottom="xs">
              <Col xs={6} middle='xs' center='xs' className='store-image-col mb5'>
                <a
                  href='https://apps.apple.com/us/app/dashboard-ae/id1325735319'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='/images/store-ios.png' alt='App Store' className='store-ios-phone' />
                </a>
                <Row>
                  <Col xs={12}>
                    <a
                      href='https://apps.apple.com/us/app/dashboard-ae/id1325735319'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img className='badge' src='/images/app-store-badge.svg' alt='Download in App Store' />
                    </a>
                  </Col>
                </Row>
              </Col>
              <Col xs={6} middle='xs' bottom='xs'  className='store-image-col mb5'>
                <a
                  href='https://play.google.com/store/apps/details?id=com.automotiveevents.dashboard'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='/images/store-google.png' alt='Google Play' className='store-play-phone' />
                </a>
                <Row>
                  <Col xs={12}>
                    <a
                      href='https://play.google.com/store/apps/details?id=com.automotiveevents.dashboard'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <img className='badge' src='/images/google-play-badge.png' alt='Download in the Google Play Store' />
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </StyledView>
  )
}

LoginView.propTypes = {
  auth: shape({
    actions: shape({
      signInUser: func.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withAuth(LoginView)
