import React from 'react'
import { func, shape } from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { styles as s, colors } from 'stylesheet'
import { Form, Field } from 'react-final-form'
import { Mutation } from 'react-apollo'
import { Col, Row } from 'react-flexbox-grid'
import { withAuth } from 'context/AuthContext'
import { Link, withRouter } from 'react-router-dom'
import gql from 'graphql-tag'

const StyledForm = styled.form({
  label: css(s.red),

  '.graph-err': css(s.block, {
    marginBottom: '1rem',
  }),

  '.fields': css(s.bgGrey, {
    paddingTop: '1.5rem',
    paddingBottom: '1.75rem',
    marginBottom: '2rem',
  }),

  '.field': {
    marginBottom: '30px',
  },

  '.label': {
    fontSize: '.85rem',
    padding: '5px',
    color: colors.white,
    backgroundColor: colors.red,
    display: 'block',
    marginBottom: '1rem',
    borderRadius: '5px',
  },

  '.graph-err': {
    fontSize: '.85rem',
    padding: '5px',
    color: colors.white,
    backgroundColor: colors.red,
    display: 'block',
    marginTop: '1rem',
    marginBottom: '1rem',
    borderRadius: '5px',
  },

  '.sign-in': {
    borderBottom: `2px solid ${colors.lightGrey}`,
  },
})

const CREATE_USER = gql`
  mutation User($email: String!, $password: String!, $attributes: JSONObject!) {
    createUser(email: $email, password: $password, attributes: $attributes) {
      uid
      attributes {
        email
        name
        postId
      }
    }
  }
`

const SignUpForm = (props) => {
  const {
    auth: {
      actions,
      // error,
      status,
    },
  } = props

  return (
    <Mutation mutation={CREATE_USER}>
      {(createUser, meta) => {
        const {
          called,
          error,
          loading,
        } = meta

        const onSubmit = async (values) => {
          const {
            attributes,
            email,
            password1,
          } = values

          await createUser({ variables: { attributes, email, password: password1 } })
          actions.signInUser(email, password1)
        }

        return (
          <Form
            onSubmit={onSubmit}
            render={(formProps) => {
              const { handleSubmit, values } = formProps

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12}>
                      <div className='fields'>
                        <Row center='xs'>
                          <Col xs={10}>
                            <Field
                              className="field"
                              label="firstName"
                              name="attributes.firstName"
                              component="input"
                              placeholder='First Name'
                            />
                          </Col>
                          <Col xs={10}>
                            <Field
                              className="field"
                              label="lastName"
                              name="attributes.lastName"
                              component="input"
                              placeholder='Last Name'
                            />
                          </Col>
                          <Col xs={10}>
                            {(error && error.code === 'auth/invalid-email') && (
                              <label className="label">Invalid Email Address</label>
                            )}
                            {(error && error.code === 'auth/email-already-in-use') && (
                              <label className="label">{error.message}</label>
                            )}
                            <Field
                              className="field"
                              label="email"
                              type="email"
                              name="email"
                              component="input"
                              placeholder='Email Address'
                            />
                          </Col>
                          <Col xs={10}>
                            {(error && error.code === 'auth/weak-password') && (
                              <label className="label">{error.message}</label>
                            )}
                            <Field
                              className="field"
                              label="password"
                              type="password"
                              name="password1"
                              component="input"
                              placeholder='Password'
                            />
                          </Col>
                          <Col xs={10}>
                            <Field
                              label="password confirmation"
                              type="password"
                              name="password2"
                              component="input"
                              placeholder='Confirm Password'
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col xs={12}>
                      {error && error.graphQLErrors.length > 0 && error.graphQLErrors.map((err) => (
                        <label className='graph-err'>{err.message}</label>
                      ))}
                      <button
                        className='submit-button'
                        // onClick={handleSubmit}
                        type="submit"
                        disabled={
                          (status.signUp === 'PENDING')
                          || (!values.password1 || values.password1 !== values.password2)
                          || (loading)
                        }
                      >
                        {loading ? 'Creating Account...' : 'Sign Up'}
                      </button>
                    </Col>
                  </Row>
                </StyledForm>
              )
            }}
          />
        )
      }}
    </Mutation>
  )
}

SignUpForm.propTypes = {
  auth: shape({
    actions: shape({
      signUpUser: func.isRequired,
    }).isRequired,
  }).isRequired,
}

export default withRouter(withAuth(SignUpForm))
